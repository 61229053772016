<template>
  <div class="w-100 h-100 overflow-auto">
    <d-remote-monitoring-templates-view
      :id="id"
      @on-create="onCreateHandler"
      @on-questionnaire="onQuestionnaireHandler"
      @on-validation-error="onOnValidationErrorHandler"
    />
  </div>
</template>

<script>
import {
  showSuccessCustomMessage,
  showValidationErrorMessage,
} from '@/helpers/messages';

export default {
  name: 'RemoteMonitoringTemplateAdd',
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this.$attrs.id;
  },
  methods: {
    onCreateHandler() {
      showSuccessCustomMessage('Данные обновлены');
      this.$router.push('/remote-monitoring/templates/');
    },
    onQuestionnaireHandler(questionnaireId) {
      const routeData = this.$router.resolve(`/remote-monitoring/questionnaires/view/${questionnaireId}/`);
      window.open(routeData.href, '_blank');
    },
    onOnValidationErrorHandler() {
      showValidationErrorMessage();
    },
  },
};
</script>
